import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DynamicContentBookmark } from "./dynamics";

export type BookmarkProps = {
  $variant?: "default" | "magazines-style";
};

export const StyledContentBookmark = styled(DynamicContentBookmark)<BookmarkProps>`
  inline-size: 18px;
  block-size: 19px;
  padding: 0;

  path {
    fill: #000000;
    opacity: 1;
  }

  ${props =>
    props.$variant === "magazines-style" &&
    css`
      path[opacity] {
        fill: #d7d7d7;
        opacity: 1;
      }
    `}
`;
