import dynamic from "next/dynamic";

import type { Props as HeaderOnelineMenuContainerProps } from "./header-oneline-menu-container";

export const DynamicHeaderAvatar = dynamic(() =>
  import("./header-avatar").then(module => module.HeaderAvatar),
);

export const DynamicHeaderOnelineMenuContainer = dynamic<HeaderOnelineMenuContainerProps>(() =>
  import("./header-oneline-menu-container").then(module => module.HeaderOnelineMenuContainer),
);
