import { useAtomValue } from "jotai";
import { type FunctionComponent, useMemo } from "react";
import { graphql, readInlineData } from "react-relay";

import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { currentArticleAtom } from "scmp-app/lib/current-item";
import type { headerContentBookmarkArticle$key } from "scmp-app/queries/__generated__/headerContentBookmarkArticle.graphql";

import { StyledContentBookmark } from "./styles";

export type Variant = "default" | "magazines-style";

export const HeaderContentBookmark: FunctionComponent = () => {
  const currentArticle = useAtomValue(currentArticleAtom);

  const variant: Variant = useMemo(() => {
    if (currentArticle && checkIsStyleArticle(currentArticle)) {
      return "magazines-style";
    }
    return "default";
  }, [currentArticle]);

  if (!currentArticle) return null;

  const data = readInlineData<headerContentBookmarkArticle$key>(
    graphql`
      fragment headerContentBookmarkArticle on Article @inline {
        entityId
      }
    `,
    currentArticle,
  );

  return (
    <StyledContentBookmark
      $variant={variant}
      entityId={data.entityId}
      ga4Events={{
        click: {
          action: "click",
          category: "bookmark",
          customized_parameters: {
            action_type: "add",
            article_id: data.entityId,
            trigger_point: "masthead",
          },
          subcategory: "",
        },
      }}
    />
  );
};

HeaderContentBookmark.displayName = "HeaderContentBookmark";
