import styled from "@emotion/styled";

import IconShare from "scmp-app/components/header/header-icon/share.svg";

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 24px;
  block-size: 24px;
`;

export const StyledIconShare = styled(IconShare)``;
