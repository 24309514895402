import styled from "@emotion/styled";

import { ArticleCommentsTrigger } from "scmp-app/components/article/article-comments-trigger";
import { CountMessage } from "scmp-app/components/article/article-comments-trigger/button/styles";

export const StyledArticleCommentsTrigger = styled(ArticleCommentsTrigger)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  inline-size: 24px;
  block-size: 24px;
  margin-inline-end: 0;

  ${CountMessage} {
    inset-block-start: 0;
  }
`;
