import styled from "@emotion/styled";
import { fontPlayfairDisplay } from "@product/scmp-sdk";

export const MenuItem = styled.span`
  padding-block-end: 4px;

  color: #222222;
  font-size: 14px;
  font-family: ${fontPlayfairDisplay};
  line-height: 18px;
  letter-spacing: -0.014px;
`;
