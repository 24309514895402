/**
 * @generated SignedSource<<aeaac583f7ee8b965ac6ab5d80d83d61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type headerContentCommentTrigger$data = {
  readonly " $fragmentSpreads": FragmentRefs<"articleCommentsTriggerArticle">;
  readonly " $fragmentType": "headerContentCommentTrigger";
};
export type headerContentCommentTrigger$key = {
  readonly " $data"?: headerContentCommentTrigger$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerContentCommentTrigger">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "headerContentCommentTrigger"
};

(node as any).hash = "a6ef270abb015cb9dedd092a19044bc8";

export default node;
