import styled from "@emotion/styled";

import { EntityLink } from "scmp-app/components/entity-link";
import { SectionStyleHeaderMenuItem } from "scmp-app/components/section/section-style/section-style-header-menu-item";

export const StyledSectionStyleHeaderMenuItem = styled(SectionStyleHeaderMenuItem)``;

export const StyledEntityLink = styled(EntityLink)`
  white-space: nowrap;
  text-transform: uppercase;

  :first-child {
    padding-inline-start: 0;
  }

  :nth-child(n + 7) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  padding-inline-end: 20px;

  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    gap: 24px;

    padding-inline-end: 24px;
  }
`;
