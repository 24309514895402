/**
 * @generated SignedSource<<32a91230ab97555864b28682f1be23bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type headerContentBookmarkArticle$data = {
  readonly entityId: string;
  readonly " $fragmentType": "headerContentBookmarkArticle";
};
export type headerContentBookmarkArticle$key = {
  readonly " $data"?: headerContentBookmarkArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerContentBookmarkArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "headerContentBookmarkArticle"
};

(node as any).hash = "2683872e652fb4471930705e868be293";

export default node;
