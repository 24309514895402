import { useSetAtom } from "jotai";
import type { FunctionComponent } from "react";

import { contentShareWidgetDrawerAtom } from "scmp-app/components/content/content-share-widget-drawer/atoms";

import { StyledIconContainer, StyledIconShare } from "./styles";

export const HeaderContentShareIcon: FunctionComponent = () => {
  const setContentShareWidgetDrawerState = useSetAtom(contentShareWidgetDrawerAtom);

  return (
    <>
      <StyledIconContainer>
        <StyledIconShare
          onClick={() => {
            setContentShareWidgetDrawerState(state => ({ ...state, isOpen: true }));
          }}
        />
      </StyledIconContainer>
    </>
  );
};

HeaderContentShareIcon.displayName = "HeaderContentShareIcon";
